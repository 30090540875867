.taggr-notice-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
  overflow: scroll;
  margin-bottom: 20px;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE & Edge */

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Edge */
  }


  .error-message {
    display: flex;
    align-items: center;
    color: red;
    font-size: 14px;
    margin-top: 8px;
  }

  .error-icon {
    font-size: 16px;
    margin-right: 5px;
  }
}

.license-plate-container {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 400px;
  width: 95%;
  overflow: auto;

  .title {
    font-size: 24px;
    font-weight: bold;
  }

  .subtitle {
    font-size: 20px;
    font-weight: bold;
  }

  .description {
    font-size: 14px;
    color: #6c757d;

    &.warning {
      color: #ff0000;
      font-size: 12px;
    }
  }

  .input-field {
    margin-bottom: 15px;
    padding: 10px;
    font-size: 16px;
    width: 100%;
    border: 2px solid #ccc;
    border-radius: 5px;
    transition: border-color 0.3s ease;

    &:focus {
      border-color: #fdff0e; /* Change this to the desired focus color */
      outline: none; /* Removes default outline */
    }
  }

  .submit-btn {
    width: 100%;
    font-size: 18px;
  }
}

/* FindYourNotice.css */

/* General Container */
.find-notice-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  width: 95%;
}

.find-notice-header {
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.find-notice-input-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  flex-direction: column;
}

.find-notice-input {
  padding: 10px;
  font-size: 16px;
  width: 80%;
  border-radius: 5px;
  border: 2px solid #ccc;
  transition: border-color 0.3s;
}

.find-notice-input:focus {
  outline: none;
  border-color: #fdff0e;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
}

/* Scrollable Notices Section */
.find-notice-notices {
  max-height: 300px;
  overflow-y: scroll;
  margin-top: 20px;
  direction: rtl;
}

.find-notice-item {
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  justify-content: space-between;
  direction: ltr;
}

.find-notice-details {
  font-size: 14px;
  color: #333;
  flex-grow: 1;
}

.notice-detail {
  margin: 5px 0;
}

.find-notice-separator {
  border-left: 1px solid #ccc;
  height: 100%;
  margin-left: 15px;
}

.find-notice-view-btn {
  background-color: green;
  color: white;
  font-size: 14px;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 80px;
}

.find-notice-view-btn:hover {
  background-color: #005f00;
}

/* Footer Styling */
.find-notice-footer {
  margin-top: 30px;
  text-align: center;
}

.find-notice-footer-text {
  font-size: 12px;
  color: #6c757d;
  margin-bottom: 10px;
}

.find-notice-footer-link {
  font-size: 14px;
  color: #007bff;
  text-decoration: none;
  margin: 0 10px;
}

.find-notice-footer-link:hover {
  text-decoration: underline;
}


.notice-container {
  .card {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    width: 95%;
  }

  .card-title {
    font-size: 24px;
    font-weight: bold;
  }

  .section-title {
    font-size: 18px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .card-details {
    border-radius: 12px;
    font-family: 'Poppins', sans-serif;
    color: #333;
    display: flex;
    flex-direction: column;
  
    .detail-row {
      display: flex;
      padding: 8px;
      background: #f7f9fc;
      border-radius: 8px;
  
      i {
        font-size: 20px;
        padding-right: 12px;
        color: #007bff;
      }
  
      .detail-label {
        font-weight: 600;
        font-size: 14px;
        color: #555;
        align-items: center;
      }
  
      .detail-value {
        font-size: 16px;
        font-weight: 500;
        color: #000;
        align-items: center;
      }
    }
  
    .icon-license-plate:before {
      content: '🚗';
    }
    .icon-tag:before {
      content: '🏷️';
    }
    .icon-violation:before {
      content: '⚠️';
    }
    .icon-date:before {
      content: '📅';
    }
    .icon-time:before {
      content: '⏰';
    }
    .icon-location:before {
      content: '📍';
    }
  }
  
  

  .card-payment-info {
    margin-top: 20px;
    p {
      margin-bottom: 10px;
    }
  }

  .btn-pay {
    background-color: #28a745;
    color: white;
    min-width: 80px;
  }

  .btn-dispute {
    background-color: #dc3545;
    color: white;
    width: 80px;
  }

}


// @media (min-width: 1024px) {
//   .taggr-notice-wrapper {
//     margin-top: 10vh;
//     transform: scale(1.2); /* Scale to 140% */
//     transform-origin: center; /* Ensure scaling happens from the center */
//   }
// }

@media (min-width: 1024px) {
  .taggr-notice-wrapper {
    margin: auto;
    margin-top: 20px;
    width: 80%; /* Increase width for better layout on desktop */
  }

  .error-message {
    font-size: 16px; /* Increase error message font size */
  }

  .license-plate-container {
    max-width: 500px; /* Increase max-width for larger desktop screens */
    padding: 30px; /* Increase padding */
  }

  .license-plate-container .title {
    font-size: 32px; /* Increase title font size */
  }

  .license-plate-container .subtitle {
    font-size: 28px; /* Increase subtitle font size */
  }

  .license-plate-container .description {
    font-size: 16px; /* Increase description font size */
  }

  .find-notice-container {
    max-width: 900px; /* Increase max-width */
    padding: 30px; /* Increase padding */
  }

  .find-notice-header {
    font-size: 32px; /* Increase header font size */
  }

  .find-notice-input-wrapper {
    width: 80%; /* Increase width for input */
  }

  .find-notice-input {
    font-size: 18px; /* Increase input field font size */
  }

  .find-notice-footer-text {
    font-size: 14px; /* Increase footer text font size */
  }

  .find-notice-footer-link {
    font-size: 16px; /* Increase footer link font size */
  }

  .find-notice-notices {
    max-height: 400px; /* Increase max height */
  }

  .find-notice-item {
    padding: 20px; /* Increase padding */
  }

  .find-notice-view-btn {
    font-size: 16px; /* Increase button font size */
    padding: 10px 20px; /* Increase button padding */
  }

  .notice-container .card {
    max-width: 900px; /* Increase card width */
    padding: 30px; /* Increase padding */
  }

  .notice-container .card-title {
    font-size: 28px; /* Increase card title font size */
  }

  .notice-container .section-title {
    font-size: 22px; /* Increase section title font size */
  }

  .notice-container .card-details p {
    font-size: 16px; /* Increase card details text size */
  }

  .notice-container .card-payment-info p {
    font-size: 16px; /* Increase payment info text size */
  }

  .btn-pay, .btn-dispute {
    font-size: 16px; /* Increase button font size */
    padding: 10px 20px; /* Increase button padding */
  }
}


.payment {
  img {
    width: 60px;
    margin: 20px;
  }
}